<template>
  <div>
    <div class="ltk" id="ltk">
      <div v-for="(item, index) in list" :key="index">
        <div v-if="item.Answertype == 'user'">
          <div class="message my_message">
            <p>{{ item.Answerinfo }}</p>
          </div>
          <img src="../assets/user.png" alt="" />
        </div>

        <div v-if="item.Answertype != 'user'">
          <img src="../assets/logo.png" alt="" />
          <div class="message frnd_message">
            <p v-if="item.Answertype == 1">{{ item.Answerinfo }}</p>
            <div
              v-else-if="item.Answertype == 2"
              @click="flowview(item.Answerinfo)"
            >
              <img :src="item.Answerinfo" alt="图片"  />
            </div>
            <div v-else-if="item.Answertype == 3">
              <img
                @click="initvideo(item.Answerinfo)"
                src="//appfile-1256233047.cos.ap-shanghai.myqcloud.com/image/videocover.jpg"
                alt=""
              />
              <!-- <video
                poster="https://appfile-1256233047.cos.ap-shanghai.myqcloud.com/image/videocover.jpg"
                controls="true"
                :src="item.Answerinfo"
                webkit-playsinline="true"
                x-webkit-airplay="true"
                playsinline="true"
                x5-video-player-type="h5"
                x5-video-player-fullscreen="true"
                preload="auto"
              ></video> -->
            </div>
            <div v-else class="answers">
              <li class="answerstitle">您是想咨询以下哪些问题</li>
              <li
                v-for="(item, index) in item"
                :key="index"
                @click="add(item.QaContentList)"
              >
                {{ index + 1 }}.{{ item.Question }}
                <span><img src="../assets/right.svg" alt="" /></span>
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="overflowy"></div>
    <div class="inputbox" id="srk">
      <div>
        <div>
          <textarea
            ref="input"
            type="textarea"
            v-model="text"
            placeholder="请用一句话简要,精准描述您的问题"
            cols="30"
            rows="10"
            @focus="focus()"
            @blur="blur()"
          ></textarea>
        </div>

        <span v-if="text != ''" @click="inittext()" class="span1"
          ><img src="../assets/fs.svg" alt=""
        /></span>
        <span v-if="text == ''" @click="inittext()" class="span2"
          ><img src="../assets/fs.svg" alt=""
        /></span>
      </div>
    </div>
    <div v-if="flag" class="flowimg1" @click="flag = false">
      <div class="comeback" @click="flag = false">
        <img src="../assets/back.svg" alt="" />
      </div>
      <img :src="flowpic" alt="" ref="pic" />
    </div>
    <div v-if="flag2" class="flowimg" @click="flag2 = false">
      <div class="comeback" @click="flag2 = false">
        <img src="../assets/back.svg" alt="" />
      </div>
      <video
        @click.stop=""
        @dblclick="flag2 = false"
        controls
        controlsList="nodownload"
        :src="videourl"
        preload="auto"
        x5-video-player-fullscreen="true"
        webkit-playsinline="false"
        x-webkit-airplay="false"
        playsinline="true"
        x5-playsinline="true"
        ref="video"
      ></video>
    </div>
  </div>
</template>
<script>
import { Toast } from "mint-ui";
import axios from "axios";
export default {
  data() {
    return {
      flag: false,
      flag2: false,
      videourl: "",
      flowpic: "",
      text: "",
      list: [],
      startx: "",
      starty: "",
      scene: "",
    };
  },
  methods: {
    focus() {
      this.$refs.input.placeholder = " ";
    },
    blur() {
      if (this.text == "") {
        this.$refs.input.placeholder = "请用一句话简要,精准描述您的问题";
      }
    },
    touch(event) {
      // console.log(event.changedTouches[0].pageX);
      // console.log(event.changedTouches[0].pageY);
      if (event.changedTouches[1]) {
        // console.log(event.changedTouches[1].pageX);
        // console.log(event.changedTouches[1].pageY);
        this.starty =
          event.changedTouches[1].pageX - event.changedTouches[0].pageX;
      }
    },
    move(event) {
      if (event.changedTouches[1]) {
        this.startx =
          event.changedTouches[1].pageX - event.changedTouches[0].pageX;
      }

      if (
        Math.abs(this.startx) - Math.abs(this.starty) <= 200 ||
        Math.abs(this.startx) - Math.abs(this.starty) >= 100
      ) {
        // alert('放大')
        this.$refs.pic.style.zoom = "2";
      } else if (Math.abs(this.startx) - Math.abs(this.starty) <= 100) {
        // alert('放大')
        this.$refs.pic.style.zoom = "1.50";
      } else {
        // alert('缩小')
        this.$refs.pic.style.zoom = "1";
      }
    },
    initvideo(url) {
      this.flag2 = true;
      this.videourl = url;
      setTimeout(() => {
        this.$refs.video.play();
      }, 500);
    },
    inittext() {
      if (this.text != "") {
        this.list.push({
          Answertype: "user",
          Answerinfo: this.text,
        });
        this.getanswerinfo();
      } else {
        let instance = Toast("不能发送空白信息");
        setTimeout(() => {
          instance.close();
        }, 2000);
      }
      this.text = "";
      setTimeout(() => {
        let div = document.getElementById("ltk");
        if (div) {
          div.scrollTop = div.scrollHeight;
        }
      }, 200);
    },
    init() {
      axios({
        method: "post",
        url: "https://apirsl.eyesreal.com:8686/AutoQaApi/GetAutoQaWithHot",
        params: {
          scene: this.scene
        },
      })
        .then((res) => {
          this.list.push(res.data.QaList);
          this.list.push({
            Answerinfo: "您好，这里是机器人小睿，请问有什么可以帮您",
            Answertype: 1,
          });
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    getanswerinfo() {
      axios({
        method: "post",
        url: "https://apirsl.eyesreal.com:8686/AutoQaApi/GetAutoQaWithQuestion",
        params: {
          question: this.text,
          scene: this.scene
        },
      })
        .then((res) => {
          if (res.data.QaNum == 1) {
            // this.list.push(res.data.QaList[0].QaContentList);
            var data = res.data.QaList[0].QaContentList;
            for (var i = 0; i <= data.length - 1; i++) {
              this.list.push(data[i]);
            }
          } else if (res.data.QaNum == 0) {
            this.list.push({
              Answertype: 1,
              Answerinfo: "抱歉,小睿不是很理解您的问题,请用一句话准确描述",
            });
          } else {
            this.list.push(res.data.QaList);
          }
          setTimeout(() => {
            let div = document.getElementById("ltk");
            if (div) {
              div.scrollTop = div.scrollHeight;
            }
          }, 2000);
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    add(data) {
      for (var i = 0; i <= data.length - 1; i++) {
        this.list.push(data[i]);
      }
      setTimeout(() => {
        let div = document.getElementById("ltk");
        if (div) {
          div.scrollTop = div.scrollHeight;
        }
      }, 200);
    },
    flowview(url) {
      this.flag = true;
      this.flowpic = url;
    },
  },
  mounted() {
    let div = document.getElementById("ltk");
    if (div) {
      div.scrollTop = div.scrollHeight;
    }
  },
  created() {
     // 直接通过 this.$route.query 获取参数
     const scene = this.$route.query.scene;
     if (typeof(scene) === 'undefined') { // 使用typeof操作符
       this.scene = 1;
     } else {
       this.scene = scene;
     }
  
    this.init();
  },
};
</script>
<style scoped>
.ltk > div > div {
  display: flex;
  width: 97%;
  margin: 0 auto;
  padding-top: 20px;
}
.ltk > div > div > img {
  margin-top: 10px;
  margin-right: 5px;
  margin-left: 5px;
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.message {
  position: relative;
  display: flex;
  width: 100%;
  margin: 10px 1vw;
}
.message p {
  position: relative;
  right: 0;
  text-align: left;
  max-width: 65%;
  padding: 12px;
  background: #c6e1fb;
  border-radius: 10px;
  font-size: 0.9em;
  word-break: break-all;
}
.message div {
  position: relative;
  right: 0;
  text-align: left;
  max-width: 100%;
  border-radius: 10px;
  font-size: 0.9em;
  word-break: break-all;
}
.message p::before {
  content: "";
  position: absolute;
  top: 10px;
  right: -5px;
  width: 15px;
  height: 15px;
  background: #c6e1fb;
  transform: rotateZ(45deg);
  border-radius: 3px;
}
.message p span {
  display: block;
  margin-top: 5px;
  font-size: 0.85em;
  opacity: 0.5;
}
.my_message {
  justify-content: flex-end;
}
.frnd_message {
  justify-content: flex-start;
}
.frnd_message p {
  background: #fff;
  text-align: left;
}
.frnd_message div {
  background: #fff;
  text-align: left;
}

.frnd_message > .answers {
  background: #fff;
  text-align: left;
  width: 70vw;
}

.message.frnd_message > p::before {
  content: "";
  position: absolute;
  top: 12px;
  left: -5px;
  width: 15px;
  height: 15px;
  background: #fff;
  transform: rotateZ(45deg);
  border-radius: 3px;
}
.frnd_message > .answers::before {
  content: "";
  position: absolute;
  top: 12px;
  left: -5px;
  width: 15px;
  height: 15px;
  background: #fff;
  transform: rotateZ(45deg);
  border-radius: 3px;
}

.frnd_message > .answers > .answerstitle {
  height: 33px;
  line-height: 40px;
  font-size: 16px;
  font-weight: bold;
  font-weight: bold;
  color: #3b3b3b;
}
/* .frnd_message > .answers > li {
  font-size: 15px;
  position: relative;
  text-align: left;
  text-indent: 1em;
  padding-right: 15px;
  height: 40px;
  line-height: 38px;
  display: flex;
  color: #3b3b3b;
} */
.frnd_message > .answers > li {
  font-size: 15px;
  position: relative;
  text-align: left;
  /* text-indent: 1em; */
  padding-left: 5px;
  padding-right: 20px;
  line-height: 1.5;
  color: #3b3b3b;
  white-space: normal;
  word-wrap: break-word;
  min-height: 30px;
}
.frnd_message > .answers > li > span {
  display: block;
  width: 14px;
  height: 14px;
  position: absolute;
  right: 13px;
  top: 11px;
}
.frnd_message > .answers > li > span > img {
  display: block;
  width: 100%;
}
.frnd_message > div > img {
  display: block;
  width: 200px;
  border-radius: 5px;
}

.frnd_message > div > video {
  display: block;
  width: 200px;
}
.comeback {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: red;
  z-index: 999;
}
</style>
